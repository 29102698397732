




























































































































































































































































































































































.auto-responder-step {
  max-width: 1140px;
  margin: 0 auto;
  .table {
    .form-group {
      margin-bottom: 0px;
    }
    textarea {
      resize: none;
    }
    td {
      vertical-align: top;
    }
  }
  .form-control.flatpickr-input {
    background: #fff !important;
  }
}
